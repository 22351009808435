import React, { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Header from "../header/Header";
import logo from "../../images/gymlogo.png";
import Footer from "../footer/Footer";
import Grid from "@mui/material/Grid";
const EmployeeAdd = () => {
  // const[id,idchange] = useState("");
  const [firstName, firstNameChange] = useState("");
  const [firstNameError, setFirstNameError] = useState("");
  const [lastName, lastNameChange] = useState("");
  const [lastNameError, setLastNameError] = useState("");
  const [email, emailChange] = useState("");
  const [emailError, setEmailError] = useState("");
  const [phone, phoneChange] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [gender, genderChange] = useState("");
  const [trainer, trainerChange] = useState("");
  const [pricepackage, packageChange] = useState("");
  const [experience, experienceChange] = useState("");
  const [startDate, setStartDate] = useState(new Date());
  const [height, setHeight] = useState("");
  const [weight, setWeight] = useState("");
  const [bmi, setBmi] = useState("");
  const [bmiResult, setBmiResult] = useState("");
  const naviage = useNavigate();
  useEffect(() => {
    if (height && weight) {
      const bmiResult = weight / (height / 100) ** 2;
      setBmi(bmiResult.toFixed(2));
      console.log(bmiResult);
      if (bmiResult < 18.5) {
        setBmiResult("Underweight");
      } else if (bmiResult < 25) {
        setBmiResult("Normal weight");
      } else if (bmiResult < 30) {
        setBmiResult("Overweight");
      } else {
        setBmiResult("Obese");
      }
    } else {
      setBmi("");
    }
  }, [height, weight]);

  const handleHeightChange = (event) => {
    setHeight(event.target.value);
  };

  const handleWeightChange = (event) => {
    setWeight(event.target.value);
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    let hasError = false;

    // eslint-disable-next-line no-cond-assign
    if (!firstName && !lastName && !email) {
      toast.error("Please fill all the required fields", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
    if (!firstName) {
      setFirstNameError("First name is required");
      hasError = true;
    } else {
      setFirstNameError("");
    }
    if (!lastName) {
      setLastNameError("Last name is required");
      hasError = true;
    } else {
      setLastNameError("");
    }
    if (!email) {
      setEmailError("Email is required");
      hasError = true;
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      setEmailError("Invalid email format");
      hasError = true;
    } else {
      setEmailError("");
    }
    if (!phone) {
      setPhoneError("Phone number is required");
      hasError = true;
    } else if (!/^\d{10,}$/.test(phone)) {
      setPhoneError("The contact number must be atleast 10 numbers");
      hasError = true;
    } else {
      setPhoneError("");
    }
    if (!hasError) {
      const empData = {
        firstName,
        lastName,
        email,
        phone,
        gender,
        trainer,
        pricepackage,
        experience,
        startDate,
        bmiResult,
      };
      console.log(empData);
      fetch("http://localhost:8000/employee", {
        method: "POST",
        headers: { "content-type": "application/json" },
        body: JSON.stringify(empData),
      })
        .then((res) => {
          toast.success("Data inserted successfylly", {
            position: "top-right",
            autoClose: 1300,
          });
          setTimeout(function () {
            naviage("/employee/list");
          }, 1400);
        })
        .catch((err) => {
          console.log(err.message);
        });
    }
  };
  return (
    <>
      <Header />
      <div className="row mt-3">
        <div>
          <img src={logo} alt="" className="fitnes-logo" />
        </div>
        <div className="container col-lg-8 mb-3">
          <form action="" className="container" onSubmit={handleSubmit}>
            <Grid container>
              <Grid item xs={12} lg={6} className="pe-lg-2">
                <TextField
                  id="outlined-basic"
                  label="First Name"
                  variant="outlined"
                  placeholder="Enter your first name here"
                  value={firstName}
                  onChange={(e) => firstNameChange(e.target.value)}
                  className="d-flex my-2 "
                  error={Boolean(firstNameError)}
                  helperText={firstNameError}
                />
              </Grid>
              <Grid item xs={12} lg={6} className="ps-lg-2">
                <TextField
                  id="outlined-basic"
                  label="Last Name"
                  variant="outlined"
                  placeholder="Enter your first name here"
                  value={lastName}
                  onChange={(e) => lastNameChange(e.target.value)}
                  className="d-flex my-2"
                  error={Boolean(lastNameError)}
                  helperText={lastNameError}
                />
              </Grid>
              <Grid item xs={12} lg={6} className="pe-lg-2">
                <TextField
                  id="outlined-basic"
                  label="Email"
                  variant="outlined"
                  placeholder="sample@gmail.com"
                  //   type="email"
                  value={email}
                  onChange={(e) => emailChange(e.target.value)}
                  className="d-flex my-2"
                  error={Boolean(emailError)}
                  helperText={emailError}
                />
              </Grid>
              <Grid item xs={12} lg={6} className="ps-lg-2">
                <TextField
                  id="outlined-number"
                  label="Mobile Number"
                  type="number"
                  value={phone}
                  onChange={(e) => phoneChange(e.target.value)}
                  className="d-flex my-2"
                  error={Boolean(phoneError)}
                  helperText={phoneError}
                />
              </Grid>

              <Grid item xs={12} lg={6} className="pe-lg-2 my-2">
                <FormControl style={{ width: "100%" }}>
                  <InputLabel id="gender-label">Gender</InputLabel>
                  <Select
                    labelId="gender-label"
                    id="gender"
                    value={gender}
                    onChange={(e) => genderChange(e.target.value)}
                    className="d-flex text-start"
                  >
                    <MenuItem value={"male"}>Male</MenuItem>
                    <MenuItem value={"female"}>Female</MenuItem>
                    <MenuItem value={"others"}>Others</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} lg={6} className="ps-lg-2 my-2">
                <FormControl style={{ width: "100%" }}>
                  <InputLabel id="trainer-label">
                    Do you require a trainer?
                  </InputLabel>
                  <Select
                    labelId="trainer-label"
                    id="trainer"
                    value={trainer}
                    onChange={(e) => trainerChange(e.target.value)}
                    className="d-flex text-start"
                  >
                    <MenuItem value={"yes"}>Yes</MenuItem>
                    <MenuItem value={"no"}>No</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} lg={6} className="pe-lg-2 my-2">
                <FormControl style={{ width: "100%" }}>
                  <InputLabel id="demo-simple-select-label text-start">
                    Select Package
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Gender"
                    value={pricepackage}
                    onChange={(e) => packageChange(e.target.value)}
                    className="d-flex text-start"
                  >
                    <MenuItem value={"monthly"}>Monthly</MenuItem>
                    <MenuItem value={"quartely"}>Quartely</MenuItem>
                    <MenuItem value={"yearly"}>Yearly</MenuItem>
                  </Select>{" "}
                </FormControl>
              </Grid>
              <Grid item xs={12} lg={6} className="ps-lg-2 my-2">
                <FormControl style={{ width: "100%" }}>
                  <InputLabel id="demo-simple-select-label text-start">
                    Have been Gym before
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Gender"
                    value={experience}
                    onChange={(e) => experienceChange(e.target.value)}
                    className="d-flex text-start"
                  >
                    <MenuItem value={"yes"}>Yes</MenuItem>
                    <MenuItem value={"no"}>No</MenuItem>
                  </Select>{" "}
                </FormControl>
              </Grid>
              <Grid item xs={12} lg={6} className="pe-lg-2">
                <TextField
                  id="outlined-number"
                  label="Select a Date"
                  type="date"
                  value={startDate.toISOString().substr(0, 10)}
                  onChange={(e) => setStartDate(new Date(e.target.value))}
                  className="d-flex my-2"
                />
              </Grid>
              <Grid item xs={12} lg={6} className="ps-lg-2">
                <TextField
                  id="outlined-number"
                  label="Height (cm)"
                  type="number"
                  value={height}
                  onChange={handleHeightChange}
                  className="d-flex my-2"
                />
              </Grid>
              <Grid item xs={12} lg={6} className="pe-lg-2">
                <TextField
                  id="outlined-number"
                  label="Weight (kg)"
                  type="number"
                  value={weight}
                  onChange={handleWeightChange}
                  className="d-flex my-2"
                />
              </Grid>
              <Grid item xs={12} lg={6} className="ps-lg-2">
                <TextField
                  id="outlined-number"
                  label="BMI"
                  type="number"
                  value={bmi}
                  className="d-flex my-2"
                  readOnly
                />
              </Grid>
              <Grid item xs={12} lg={6} className="pe-lg-2">
                <TextField
                  id="outlined-number"
                  label="Result"
                  type="text"
                  value={bmiResult}
                  className="d-flex my-2"
                  readOnly
                />
              </Grid>

              <Grid item xs={12} lg={12} className="text-start">
                <Button color="warning" variant="outlined" type="submit">
                  Submit
                </Button>
                <ToastContainer />
              </Grid>
            </Grid>
          </form>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default EmployeeAdd;
