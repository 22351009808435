import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import EmployeeList from "./components/list/EmployeeList";
import EmployeeAdd from "./components/add/EmployeeAdd";
import EmpEdit from "./components/edit/EmpEdit";
function App() {
  return (
    <div className="App overflow-hidden">
      <BrowserRouter>
        <Routes>
          <Route path="/employee/list" element={<EmployeeList/>}></Route>
          <Route path="/" element={<EmployeeAdd/>}></Route>
          <Route path="/employee/edit/:empid" element={<EmpEdit/>}></Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
