import React, { useState } from "react";
import { useEffect } from "react";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import IconButton from "@mui/material/IconButton";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import DeleteIcon from "@mui/icons-material/Delete";
import Modal from "react-bootstrap/Modal";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import Footer from "../footer/Footer";

const Table = () => {
  const [empdata, empdataChange] = useState(null);
  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);
  const [selectedEmpId, setSelectedEmpId] = useState(null);
  const navigate = useNavigate();

  const employeeEdit = (id) => {
    navigate("/employee/edit/" + id);
  };
  const employeeDelete = (id) => {
    confirmAlert({
      title: "Confirm to submit",
      message: "Are you sure to do this.",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            fetch("http://localhost:8000/employee/" + id, {
              method: "DELETE",
            })
              .then((res) => {
                toast.success("Queriesf removed successfully", {
                  position: "top-right",
                  autoClose: 1300,
                  style: {
                    fontSize: "14px",
                  },
                });
                setTimeout(function () {
                  window.location.reload();
                }, 1400);
              })
              .catch((err) => {
                console.log(err.message);
              });
          },
        },
        {
          label: "No",
          onClick: () => {
            toast.warning("Action Rejected", {
              position: "top-right",
              autoClose: 1300,
              style: {
                fontSize: "20px",
              },
            });
          },
        },
      ],
    });
  };

  useEffect(() => {
    fetch("http://localhost:8000/employee")
      .then((res) => {
        return res.json();
      })
      .then((resp) => {
        empdataChange(resp);
      })
      .catch((err) => {
        console.log(err.message);
      });
  }, []);
  // When an employee is selected
  const handleEmpClick = (id) => {
    setSelectedEmpId(id);
    setOpen(true);
  };

  return (
    <>
      <div className="container mt-5">
        <div className="card">
          <div className="card-body table-responsive">
            <table className="table table-bordered text-center table-hover table-striped caption-top text-nowrap">
              <caption>List of Enquiries</caption>
              <thead className="bg-dark text-white">
                <tr>
                  <th>id</th>
                  <th>first name</th>
                  <th>last name</th>
                  <th>email</th>
                  <th>phone</th>
                  <th>gender</th>
                  <th>trainer</th>
                  <th>price package</th>
                  <th>experience</th>
                  <th>Enquiry Date</th>
                  <th>Bmi Result</th>
                  <th>actions</th>
                </tr>
              </thead>
              <tbody>
                {empdata &&
                  empdata.map((item) => (
                    <tr key={item.id}>
                      <td>{item.id}</td>
                      <td>{item.firstName}</td>
                      <td>{item.lastName}</td>
                      <td>{item.email}</td>
                      <td>{item.phone}</td>
                      <td>{item.gender}</td>
                      <td>{item.trainer}</td>
                      <td>{item.pricepackage}</td>
                      <td>{item.experience}</td>
                      <td>{item.startDate.substr(0, 10)}</td>
                      <td>{item.bmiResult}</td>
                      <td className="d-flex">
                        <IconButton
                          aria-label="edit"
                          color="info"
                          onClick={() => handleEmpClick(item.id)}
                        >
                          <RemoveRedEyeOutlinedIcon />
                        </IconButton>
                        <IconButton
                          aria-label="edit"
                          color="warning"
                          onClick={() => employeeEdit(item.id)}
                        >
                          <EditRoundedIcon />
                        </IconButton>
                        <IconButton
                          aria-label="delete"
                          color="error"
                          onClick={() => employeeDelete(item.id)}
                        >
                          <DeleteIcon />
                          <ToastContainer />
                        </IconButton>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <Footer />
      <Modal
        show={open}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>User Deatils</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            {empdata &&
              empdata
                .filter((item) => item.id === selectedEmpId)
                .map((item) => (
                  <ul key={item.id} class="list-unstyled">
                    <li>
                      First Name: <span className="mx-2">{item.firstName}</span>
                    </li>
                    <li>
                      Last Name: <span className="mx-2">{item.lastName}</span>
                    </li>
                    <li>
                      Email: <span className="mx-2">{item.email}</span>
                    </li>
                  </ul>
                ))}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Table;
