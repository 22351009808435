import React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import { useNavigate } from "react-router-dom";

const Header = () => {
  const navigate = useNavigate();
  const handleViewEnquiries = () => {
    navigate("/employee/list");
  };
  return (
    <>
      <Box sx={{ flexGrow: 1 }}>
        <AppBar position="static" className="bg-danger p-3">
          <Toolbar>
            <Grid container spacing={2}>
              <Grid item xs={12} md={8}>
                <Typography
                  variant="h6"
                  component="div"
                  className="text-lg-start ms-lg-5 text-uppercase"
                >
                  gym registration app
                </Typography>
              </Grid>
              <Grid item xs={12} md={4}>
                <Button
                  variant="contained"
                  className="text-dark bg-warning"
                  onClick={handleViewEnquiries}
                >
                  view enquiries
                </Button>
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
      </Box>
    </>
  );
};

export default Header;
