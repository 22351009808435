import React from "react";
import "./footer.css";
const Footer = () => {
  return (
    <>
      <div className="bg-danger p-1 p-lg-3 position-fixe w-100 bottom-0 footer-container">
        <div className="d-flex flex-column flex-lg-row align-items-center justify-content-between container">
          <h5 className="text-light">Fitness First</h5>
          <h5 className="text-light">
            {" "}
            Copywrite © {new Date().getFullYear()} Pravin Amalan
          </h5>
        </div>
      </div>
    </>
  );
};

export default Footer;
