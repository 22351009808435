import React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Table from "./Table";
import { Link } from "react-router-dom";
const EmployeeList = () => {
  return (
    <>
      <Box sx={{ flexGrow: 1 }}>
        <AppBar position="static" className="bg-danger p-3">
          <Toolbar>
            <Grid container spacing={2}>
              <Grid item xs={12} md={8}>
                <Typography
                  variant="h6"
                  component="div"
                  className="text-lg-start ms-lg-5 text-uppercase"
                >
                  gym registration app
                </Typography>
              </Grid>
              <Grid item xs={12} md={4}>
                <Link to="/" className="text-white text-decoration-none">
                  <Button variant="contained" className="text-dark bg-warning">
                    Add New Queries
                  </Button>
                </Link>
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
      </Box>
      <Table />
    </>
  );
};

export default EmployeeList;
